import axios from 'axios'

import { environment } from 'environment'

export const getAppContext = (appId: string, instanceId: string, config: any, callback: Function) => {
  const url = `${environment.preciousServiceUrl}/app/${appId}/instance/${instanceId}/context`

  axios
    .get(url, config)
    .then(resp => callback(resp))
    .catch(error => console.dir(error))
}
