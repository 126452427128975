import { WppButton, WppIconSearch } from '@platform-ui-kit/components-library-react'
import React from 'react'

import styles from 'components/EmptyObjectsList/EmptyObjectsList.module.scss'

export interface Props {
  primaryText: string
  secondaryText: string
  buttonText: string
  callback: Function
}

export const EmptyObjectsList = ({ primaryText, secondaryText, buttonText, callback }: Props) => {
  return (
    <div className={styles.section}>
      <div className={styles.actionControls}>
        <h2 className={styles.infoText}>{primaryText}</h2>
        <p className={styles.secondaryText}>{secondaryText}</p>
        <WppButton className={styles.audiencesButton} variant="primary" onClick={() => callback()}>
          <WppIconSearch slot="icon-start" />
          {buttonText}
        </WppButton>
      </div>
    </div>
  )
}
