export const PRODUCER_APP_ID = 'f0ce5c65-f571-4cda-b038-c9ceae0fb3d4'
export const PRODUCER_INSTANCE_ID = 'fadd03b2-c359-40e6-88cd-1f0d454608e3'

export interface ConsumerConnectionInfo {
  connectionId: string
  dataType: string
  version: string
  producerAppId: string
  producerInstanceId: string
  producerBaseUrl: string
}

export interface ProducerConnectionInfo {
  connectionId: string
  dataType: string
  version: string
  targetAppId: string
  targetContainerId: string
}

export interface ConnectionsContext {
  asProducer: ProducerConnectionInfo[]
  asConsumer: ConsumerConnectionInfo[]
}

export interface AppInfoContext {
  appName: string
  appId: string
  appInstanceId: string
}
