// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Yj7VI{display:flex;justify-content:flex-end}.Yj7VI>*{margin-right:10px}.k03TT{font-size:18px}", "",{"version":3,"sources":["webpack://./src/components/DeleteItemModal/DeleteItemModal.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,wBAAA,CAGF,SACE,iBAAA,CAGF,OACE,cAAA","sourcesContent":[".actionButtons {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.actionButtons > * {\n  margin-right: 10px;\n}\n\n.title {\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionButtons": "Yj7VI",
	"title": "k03TT"
};
export default ___CSS_LOADER_EXPORT___;
