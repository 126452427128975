// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XNnDl{display:grid;grid-gap:28px;grid-template-columns:1fr 1fr 1fr 1fr;margin-bottom:28px}.XNnDl>*{font-size:14px}.UyiSx{font-weight:600;font-size:28px}.RauCY{display:flex;align-items:center;justify-content:space-between;height:146px}.W2Gnz{justify-self:flex-end}", "",{"version":3,"sources":["webpack://./src/components/AudiencesList/AudiencesList.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,aAAA,CACA,qCAAA,CACA,kBAAA,CAGF,SACE,cAAA,CAGF,OACE,eAAA,CACA,cAAA,CAGF,OACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,YAAA,CAGF,OACE,qBAAA","sourcesContent":[".cards {\n  display: grid;\n  grid-gap: 28px;\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n  margin-bottom: 28px;\n}\n\n.cards > * {\n  font-size: 14px;\n}\n\n.objectTitle {\n  font-weight: 600;\n  font-size: 28px;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: 146px;\n}\n\n.createAudienceButton {\n  justify-self: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cards": "XNnDl",
	"objectTitle": "UyiSx",
	"header": "RauCY",
	"createAudienceButton": "W2Gnz"
};
export default ___CSS_LOADER_EXPORT___;
