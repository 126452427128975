import { WppButton, WppIconAdd } from '@platform-ui-kit/components-library-react'
import React from 'react'

import styles from 'components/AudiencesList/AudiencesList.module.scss'
import { DataObjectItem, AudienceContainerData } from 'components/DataObjectItem/DataObjectItem'
import { EmptyObjectsList } from 'components/EmptyObjectsList/EmptyObjectsList'
import { LoadingComponent } from 'components/EmptyObjectsList/LoadingComponent'

interface Props {
  audiences: { data: any; itemId: string; objectVersion: string }[]
  reloadAudiences: Function
  openCreateAudienceModal: Function
  openDeleteItemModal: { (itemId: string, itemName: string): any }
  isLoading: boolean
}

const genders: { [index: string]: string } = {
  '1': 'male',
  '2': 'female',
}

export const AudiencesList = ({
  audiences,
  isLoading,
  reloadAudiences,
  openDeleteItemModal,
  openCreateAudienceModal,
}: Props) => {
  const prepareAudienceContainerData = (
    audienceOrigin: { data: any; itemId: string; objectVersion: string },
    sourceAppName: string,
  ): AudienceContainerData => {
    let audience: AudienceContainerData = {
      audienceVersion: audienceOrigin.objectVersion,
      producerItemId: audienceOrigin.itemId,
      id: audienceOrigin.data.id,
      name: audienceOrigin.data.name,
      description: audienceOrigin.data.description,
      imagePath: `/_assets/audiences/icons/${audienceOrigin.data.imagePath}`,
      level: audienceOrigin.data.level,
      definitionsDataTypes: [],
      budget: `$${audienceOrigin.data.averagePurchaseValue * audienceOrigin.data.numberOfPurchases}`,
      objectType: 'audience',
      sourceName: sourceAppName,
    }

    if (audienceOrigin.data.definitions.length > 0) {
      audience.definitionsDataTypes = audienceOrigin.data.definitions.map((item: any) => item.dataType)

      if (
        audienceOrigin.data.definitions[0].filterTree &&
        audienceOrigin.data.definitions[0].filterTree.filters &&
        audienceOrigin.data.definitions[0].filterTree.filters[0].filterField === 'Q02_Gender'
      ) {
        const genderFilterValue = audienceOrigin.data.definitions[0].filterTree.filters[0].filterValue as string
        audience.gender = genders[genderFilterValue]
      }
      if (
        audienceOrigin.data.definitions[0].filterTree &&
        audienceOrigin.data.definitions[0].filterTree.filters &&
        audienceOrigin.data.definitions[0].filterTree.filters.length > 1 &&
        audienceOrigin.data.definitions[0].filterTree.filters[1].filterField === 'DEM02'
      ) {
        audience.hasChildren =
          audienceOrigin.data.definitions[0].filterTree.filters[1].filterValue === '1' ? 'yes' : 'no'
      }
    }
    return audience
  }

  const serializedAudiences = audiences.map((item: { data: any; itemId: string; objectVersion: string }) =>
    prepareAudienceContainerData(item, 'Demo Data Producer'),
  )

  return (
    <>
      <div className={styles.header}>
        <h1 className={styles.objectTitle}>Audiences</h1>
        <WppButton
          className={styles.createAudienceButton}
          variant="primary"
          size="m"
          onClick={() => {
            openCreateAudienceModal()
          }}
        >
          <WppIconAdd slot="icon-start" />
          Create new audience
        </WppButton>
      </div>

      {isLoading ? (
        <LoadingComponent />
      ) : serializedAudiences.length > 0 ? (
        <div className={styles.cards}>
          {serializedAudiences.map((item: any) => (
            <DataObjectItem key={item.id} itemData={item} showAction={true} openDeleteItemModal={openDeleteItemModal} />
          ))}
        </div>
      ) : (
        <EmptyObjectsList
          primaryText="Loading your linked audiences"
          secondaryText="Press get in order to get a list of found audiences"
          buttonText="get audiences"
          callback={reloadAudiences}
        />
      )}
    </>
  )
}
