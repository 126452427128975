import {
  WppCard,
  WppIconMore,
  WppTypography,
  WppActionButton,
  WppMenuContext,
  WppListItem,
  WppIconTrash,
  WppIconGlobe,
  WppIconMoney,
  WppIconUser,
  WppIconMultipleEmotions,
} from '@platform-ui-kit/components-library-react'
import React from 'react'

import styles from 'components/DataObjectItem/DataObjectItem.module.scss'

export interface AudienceContainerData {
  audienceVersion: string
  producerItemId: string
  id: string
  name: string
  description: string
  gender?: string
  hasChildren?: string
  definitionsDataTypes?: string[]
  imagePath: string
  level: string
  budget: string
  objectType: string
  sourceName: string
}

export interface Props {
  openDeleteItemModal: { (itemId: string, itemName: string): any }
  itemData: AudienceContainerData
  showAction?: Boolean
}

export const DataObjectItem = ({ itemData, showAction, openDeleteItemModal }: Props) => {
  return (
    <WppCard className={styles.card} key={itemData.name}>
      <div slot="header">
        <WppTypography>
          <img className={styles.audienceIcon} alt="" src={itemData.imagePath} />
        </WppTypography>
      </div>
      <div slot="actions">
        {showAction && (
          <WppMenuContext key={itemData.id} dropdownConfig={{ appendTo: () => document.body }}>
            <WppActionButton slot="trigger-element" variant="secondary">
              <WppIconMore slot="icon-start" direction="horizontal" />
            </WppActionButton>
            <WppListItem
              onWppChangeListItem={() => openDeleteItemModal(itemData.id, itemData.name)}
              data-testid="context-app-launch"
            >
              <WppIconTrash slot="left" />
              <WppTypography slot="label" type="s-body">
                Delete item
              </WppTypography>
            </WppListItem>
          </WppMenuContext>
        )}
      </div>
      <div className={styles.audienceItemBlock}>
        <span className={styles.itemName}>{itemData.name}</span>
        <div className={styles.createdBy}>version: {itemData.audienceVersion}</div>
      </div>
      <div className={styles.objectTypeSection}>
        <div className={styles.objectTypeSectionItem}>{itemData.objectType}</div>
        <div className={styles.objectTypeSectionItem}>
          {itemData.level === 'market' ? 'predefined' : 'user defined'}
        </div>
      </div>
      <div className={styles.levelSection}>
        <WppIconGlobe size="s" />
        <div className={styles.levelSectionItem}>Level: {itemData.level}</div>
      </div>
      <div className={styles.levelSection}>
        <WppIconMoney size="s" />
        <div className={styles.levelSectionItem}>{itemData.budget}</div>
      </div>
      {itemData.gender && (
        <div className={styles.levelSection}>
          <WppIconUser size="s" />
          <div className={styles.levelSectionItem}>Gender: {itemData.gender}</div>
        </div>
      )}
      {itemData.hasChildren && (
        <div className={styles.levelSection}>
          <WppIconMultipleEmotions size="s" />
          <div className={styles.levelSectionItem}>Has children: {itemData.hasChildren}</div>
        </div>
      )}
    </WppCard>
  )
}
