// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".C0bIb{margin-top:16px}.zCBER{margin-bottom:8px}.s88xJ+.s88xJ{margin-top:8px}.jNQ1O{display:block;color:var(--wpp-grey-color-600)}", "",{"version":3,"sources":["webpack://./src/pages/navigationAndWorkspaces/Section/Section.module.scss"],"names":[],"mappings":"AAAA,OACE,eAAA,CAGF,OACE,iBAAA,CAIA,cACE,cAAA,CAIJ,OACE,aAAA,CACA,+BAAA","sourcesContent":[".section {\n  margin-top: 16px;\n}\n\n.cardTitle {\n  margin-bottom: 8px;\n}\n\n.infoItem {\n  & + & {\n    margin-top: 8px;\n  }\n}\n\n.label {\n  display: block;\n  color: var(--wpp-grey-color-600);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "C0bIb",
	"cardTitle": "zCBER",
	"infoItem": "s88xJ",
	"label": "jNQ1O"
};
export default ___CSS_LOADER_EXPORT___;
