import { WppSpinner } from '@platform-ui-kit/components-library-react'
import {} from 'components/EmptyObjectsList/EmptyObjectsList.module.scss'
import React from 'react'

import styles from './EmptyObjectsList.module.scss'

export const LoadingComponent = () => {
  return (
    <div className={styles.section}>
      <WppSpinner data-testid="loading-page-spinner" size="l" />
    </div>
  )
}
