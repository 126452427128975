import { WppInlineMessage } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import axios from 'axios'
import React from 'react'

import { AudiencesList } from 'components/AudiencesList/AudiencesList'
import { CreateAudienceModal } from 'components/CreateAudienceModal/CreateAudienceModal'
import { DeleteItemModal } from 'components/DeleteItemModal/DeleteItemModal'
import { environment } from 'environment'

import { AppInfoContext, ConnectionsContext, PRODUCER_APP_ID, PRODUCER_INSTANCE_ID } from './config'
import { getAppContext } from './getContext'

interface deleteItemModalConfig {
  itemName: string
  itemId: string
}

export const AudiencesPage = () => {
  const { osContext, osApi } = useOs()

  const appInfoContext: AppInfoContext = {
    appName: 'Producer',
    appId: PRODUCER_APP_ID,
    appInstanceId: PRODUCER_INSTANCE_ID,
  }

  const config = {
    headers: {
      Authorization: `Bearer ${osApi.getAccessToken()}`,
      Workspace: osContext.workspace && osContext.workspace.azId,
    },
  }

  const [isLoading, setIsLoading] = React.useState(true)

  const [openDeleteItemModal, setOpenDeleteItemModal] = React.useState(false)
  const [openCreateAudienceModal, setOpenCreateAudienceModal] = React.useState(false)
  const [deleteItemModalConfig, setDeleteItemModalConfig] = React.useState<MayBeNull<deleteItemModalConfig>>(null)

  // const [dataTransferConnectionsContext, setDataTransferConnectionsContext] =
  //   React.useState<MayBeNull<ConnectionsContext>>(null)

  const [serializedAudiences, setSerializedAudiences] = React.useState<
    { data: object; itemId: string; objectVersion: string }[]
  >([])

  const [errorMessage, setErrorMessage] = React.useState('operation failed')
  const [isErrorMessage, setIsErrorMessage] = React.useState(false)
  const [isSuccessMessage, setIsSuccessMessage] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('operation success')

  const validateWorkspace = () => {
    if (!osContext.workspace) {
      setErrorMessage('Workspace is not set')
      setIsErrorMessage(true)
    }
  }

  const handleOpenDeleteItemModal = (itemId: string, itemName: string): any => {
    setDeleteItemModalConfig({ itemId, itemName })
    setOpenDeleteItemModal(true)
  }

  const handleDeleteItem = () => {
    axios
      .delete(`${environment.demoProducerServiceUrl}/v2/audiences/${deleteItemModalConfig?.itemId}`)
      .then(() => {
        showSuccessMessage(`item '${deleteItemModalConfig?.itemName}' deleted`)
        setOpenDeleteItemModal(false)
        getCreatedAudiences()
      })
      .catch(error => {
        console.error('Error while deleting item', error)
        showErrorMessage(`Item '${deleteItemModalConfig?.itemName}' wasn't deleted. Message: ${error.message}`)
        setOpenDeleteItemModal(false)
      })
  }

  const handleCloseDeleteItemModal = () => {
    setOpenDeleteItemModal(false)
  }

  const showCreateAudienceModal = () => {
    setOpenCreateAudienceModal(true)
  }

  const showSuccessMessage = (message: string) => {
    setIsSuccessMessage(true)
    setSuccessMessage(message)
    setTimeout(() => {
      setIsSuccessMessage(false)
      setSuccessMessage('')
    }, 2000)
  }

  const showErrorMessage = (message: string) => {
    setIsErrorMessage(true)
    setErrorMessage(message)
    setTimeout(() => {
      setIsErrorMessage(false)
      setErrorMessage('')
    }, 9000)
  }

  const handleCreateAudienceSuccess = (message: string) => {
    showSuccessMessage(message)
    getCreatedAudiences()
  }

  const handleCreateAudienceFail = (message: string) => {
    showErrorMessage(message)
  }

  const setConnectionConfigCallback = (response: {
    data: { connections: ConnectionsContext }
    isError: boolean
    message: string
  }) => {
    console.log('Connections', response.data)
  }
  React.useEffect(
    () => getAppContext(appInfoContext.appId, appInfoContext.appInstanceId, config, setConnectionConfigCallback),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  React.useEffect(
    () => getCreatedAudiences(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  React.useEffect(
    () => validateWorkspace(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const getCreatedAudiences = () => {
    setIsLoading(true)
    axios
      .get(`${environment.demoProducerServiceUrl}/v2/audiences`, config)
      .then(resp => {
        const preparedAudiences = resp.data.map((item: any) => ({
          data: item.data,
          itemId: item.id,
          objectVersion: item.version_id,
        }))
        setSerializedAudiences(preparedAudiences)
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)
        showErrorMessage(`Can't get audiences. Message: ${error.message}`)
      })
  }

  return (
    <>
      <AudiencesList
        audiences={serializedAudiences}
        reloadAudiences={getCreatedAudiences}
        isLoading={isLoading}
        openDeleteItemModal={handleOpenDeleteItemModal}
        openCreateAudienceModal={showCreateAudienceModal}
      />

      {isErrorMessage && <WppInlineMessage size="s" message={errorMessage} type="error" showTooltipFrom={200} />}

      {isSuccessMessage && <WppInlineMessage size="s" message={successMessage} type="success" showTooltipFrom={200} />}
      <DeleteItemModal
        itemName={deleteItemModalConfig?.itemName}
        handleDeleteItem={handleDeleteItem}
        handleCloseModal={handleCloseDeleteItemModal}
        isOpen={openDeleteItemModal}
      />
      <CreateAudienceModal
        isOpen={openCreateAudienceModal}
        successCallback={handleCreateAudienceSuccess}
        errorCallback={handleCreateAudienceFail}
        closeModal={() => setOpenCreateAudienceModal(false)}
      />
    </>
  )
}
