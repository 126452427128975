// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IkUBU{display:flex;align-items:center;justify-content:center;width:1364px;height:400px}.sHZ_Q{display:flex;font-weight:500;font-size:18px;line-height:10px}.rnyWV{color:var(--grey-800, #4d5358);font-size:14px}.OyulM{display:flex;flex-direction:column;gap:24px;align-items:center;justify-content:center;color:var(--grey-1000, #121619)}", "",{"version":3,"sources":["webpack://./src/components/EmptyObjectsList/EmptyObjectsList.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA,CACA,YAAA,CAGF,OACE,YAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAGF,OACE,8BAAA,CACA,cAAA,CAGF,OACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,kBAAA,CACA,sBAAA,CACA,+BAAA","sourcesContent":[".section {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 1364px;\n  height: 400px;\n}\n\n.infoText {\n  display: flex;\n  font-weight: 500;\n  font-size: 18px;\n  line-height: 10px;\n}\n\n.secondaryText {\n  color: var(--grey-800, #4d5358);\n  font-size: 14px;\n}\n\n.actionControls {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  align-items: center;\n  justify-content: center;\n  color: var(--grey-1000, #121619);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "IkUBU",
	"infoText": "sHZ_Q",
	"secondaryText": "rnyWV",
	"actionControls": "OyulM"
};
export default ___CSS_LOADER_EXPORT___;
