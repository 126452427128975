import {
  WppButton,
  WppCard,
  WppInput,
  WppListItem,
  WppModal,
  WppSelect,
  WppToggle,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import axios from 'axios'
import React from 'react'

import { environment } from '../../environment'
import styles from '../DeleteItemModal/DeleteItemModal.module.scss'

interface Props {
  isOpen: boolean
  successCallback: { (message: string): any }
  errorCallback: { (message: string): any }
  closeModal: Function
}

const versions = ['architect_audience_v1', 'architect_audience_v2', 'architect_audience_v3']

export const CreateAudienceModal = ({ isOpen, successCallback, errorCallback, closeModal }: Props) => {
  const { osContext, osApi } = useOs()
  const { email } = osContext.userDetails

  const [isLoading, setIsLoading] = React.useState(false)
  const hasChildrenRef = React.useRef<HTMLWppToggleElement>(null)
  const versionRef = React.useRef<HTMLWppSelectElement>(null)

  const [version, setVersion] = React.useState('architect_audience_v2')
  const [audienceName, setAudienceName] = React.useState('')
  const [audienceDescription, setAudienceDescription] = React.useState('')
  const [gender, setGender] = React.useState('male')
  const [hasChildren, setHasChildren] = React.useState(false)

  const config = {
    headers: {
      Authorization: `Bearer ${osApi.getAccessToken()}`,
      Workspace: (osContext.workspace && osContext.workspace.azId) || '825ec2dc-2fe4-43a1-bf16-4d0b81161054',
    },
  }

  const createAudience = () => {
    console.log(`has children: ${hasChildren}`)
    setIsLoading(true)
    const newAudienceData = {
      connectionId: null,
      dataType: 'audiences',
      version: version,
      payload: {
        name: audienceName,
        description: audienceDescription,
        createdBy: email,
        gender: gender,
        hasChildren: hasChildrenRef.current && hasChildrenRef.current.checked,
      },
    }

    axios
      .post(`${environment.demoProducerServiceUrl}/v2/audiences`, newAudienceData, config)
      .then(res => {
        setIsLoading(false)
        console.log('audience successfully created')
        console.dir(res.data.data)
        successCallback('audience successfully created')
        closeModal()
      })
      .catch(error => {
        console.error('Creation audience error', error)
        errorCallback("audience wasn't created")
        closeModal()
        setIsLoading(false)
      })
    console.log('audience data')
    console.dir(newAudienceData)
  }

  const handleAudienceNameChange = (event: CustomEvent) => {
    setAudienceName(event.detail.value)
  }

  const handleAudienceDescriptionChange = (event: CustomEvent) => {
    setAudienceDescription(event.detail.value)
  }

  const handleVersionChange = (event: CustomEvent) => {
    setVersion(event.detail.value)
  }

  return (
    <>
      <WppModal open={isOpen} size="m">
        <h3 slot="header">Create audience</h3>
        <div slot="body">
          <WppSelect
            required
            className="wpp-spacing-8-bottom"
            labelConfig={{ text: 'Audience object version', locales: { optional: 'Optional' } }}
            placeholder="select audience version..."
            defaultValue={versions[0]}
            onWppChange={handleVersionChange}
            ref={versionRef}
          >
            {versions.map((item: string) => (
              <WppListItem value={item} key={item}>
                <p slot="label">{item}</p>
              </WppListItem>
            ))}
          </WppSelect>
          <WppCard className={`${styles.card} wpp-spacing-8-bottom`} key="Generate Audience">
            <WppInput
              required
              className="wpp-spacing-8-bottom"
              labelConfig={{ text: 'Audience name' }}
              name="audienceName"
              placeholder="name"
              onWppChange={handleAudienceNameChange}
            />
            <WppInput
              required
              className="wpp-spacing-8-bottom"
              labelConfig={{ text: 'Audience description' }}
              name="audienceDescription"
              placeholder="description"
              onWppChange={handleAudienceDescriptionChange}
            />
          </WppCard>
          <div className={`${styles.card} wpp-spacing-8-bottom`} key="Select gender">
            <WppTypography className={styles.cardTitle} type="l-strong" tag="h4">
              Configure definition
            </WppTypography>
            <div className={styles.label}>
              <WppSelect
                required
                className="wpp-spacing-8-bottom"
                labelConfig={{ text: 'Gender' }}
                size="s"
                value={gender}
                placeholder="Select gender..."
                onWppChange={({ detail: { value } }) => setGender(value as string)}
              >
                <WppListItem value="male" key="male">
                  <p slot="label">Male</p>
                </WppListItem>
                <WppListItem value="female" key="female">
                  <p slot="label">Female</p>
                </WppListItem>
              </WppSelect>
            </div>
            <div className={`${styles.card} ${styles.label}`}>
              <WppToggle
                required
                ref={hasChildrenRef}
                labelConfig={{ text: 'has children' }}
                checked={false}
                onWppChange={({ detail: { checked } }) => setHasChildren(checked)}
              />
            </div>
          </div>
        </div>
        <div slot="actions" className={styles.actionButtons}>
          <WppButton
            disabled={audienceName.length === 0}
            loading={isLoading}
            variant="primary"
            onClick={() => createAudience()}
          >
            create audience
          </WppButton>
          <WppButton variant="secondary" onClick={() => closeModal()}>
            close
          </WppButton>
        </div>
      </WppModal>
    </>
  )
}
