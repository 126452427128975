import { WppModal, WppButton } from '@platform-ui-kit/components-library-react'
import React from 'react'

import styles from 'components/DeleteItemModal/DeleteItemModal.module.scss'

interface Props {
  itemName?: string
  isOpen: boolean
  handleCloseModal: Function
  handleDeleteItem: Function
}

export const DeleteItemModal = ({ itemName, isOpen, handleDeleteItem, handleCloseModal }: Props) => {
  return (
    <>
      <WppModal open={isOpen} size="m">
        <h3 slot="header">Delete item</h3>
        <p slot="body" className={styles.title}>
          Are you sure you want to delete item '{itemName}'?
        </p>
        <div slot="actions" className={styles.actionButtons}>
          <WppButton variant="primary" size="s" onClick={() => handleDeleteItem()}>
            yes
          </WppButton>
          <WppButton variant="secondary" size="s" onClick={() => handleCloseModal()}>
            no
          </WppButton>
        </div>
      </WppModal>
    </>
  )
}
